<template>
  <q-dialog full-height v-model="isShowDialog" persistent>
    <q-card class="notification-card">
      <section class="q-px-sm">
        <div class="flex row justify-start items-center header">
          <h6 class="title-card">
            {{ post == null ? "Criar novo post" : "Editar post" }}
          </h6>
          <div class="flex row justify-end action-header">
            <q-btn-dropdown
              color="default-pink"
              text-color="white"
              no-caps
              unelevated
              dense
              icon="o_sell"
              :label="postForm.tagnNameSelected"
              :rules="[(val) => val.length !== '' || 'Campo Obrigatório']"
            >
              <q-list>
                <template  v-for="item in filterListItens" :key="item.value">
                  <q-item
                      clickable
                      v-close-popup
                      v-if="item.active"
                      @click="selectTag(item.value, item.name)"
                  >
                    <q-item-section>
                      <q-item-label>{{ item.name }}</q-item-label>
                    </q-item-section>
                  </q-item>
                </template>

              </q-list>
            </q-btn-dropdown>
          </div>
        </div>

        <slot v-if="isLoading" name="loading"></slot>

        <q-form v-else ref="formRef">
          <q-checkbox
            v-model="postForm.editOtherTutor"
            label="Ativar edição compartilhada"
            color="default-pink"
          />
          <q-input
            type="text"
            class="q-py-sm q-px-sm"
            dense
            placeholder="Escreva um titulo"
            v-model="postForm.titlePost"
            maxlength="500"
            :rules="[
              (val) => val.length != '' || 'Campo Obrigatório',
              (val) => val.length <= 500 || 'máximo de 500 caracteres',
            ]"
          />

          <div class="flex row justify-between items-center col-12">
            <q-select
              class="q-py-sm q-px-sm col-6"
              dense
              emit-value
              map-options
              use-input
              input-debounce="0"
              v-model="postForm.category_id"
              :options="listCategories"
              label="Selecione uma categoria"
              color="default-pink"
              :rules="[(val) => !!val  || 'Campo categoria']"
            >
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">
                    Não encontrado
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
            <div
              v-if="canAddCategory"
              class="form-input flex justify-center col-4 column"
            >
              <OutlineBtn
                titleButton="Cadastrar categorias de posts"
                :showIcon="false"
                @click="addCategory = true"
              />
            </div>
          </div>

          <div class="flex column col-12">
            <q-select
              dense
              color="default-pink"
              class="q-py-sm q-px-sm col-6"
              label="Selecione um segmento"
              multiple
              use-chips
              use-input
              emit-value
              map-options
              @filter="filterFnBranches"
              v-model="postForm.branches"
              :options="listBranches"
            >
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">
                    Não encontrado
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
            <div class="row justify-between items-center">
              <q-select
                class="col-10 q-py-sm q-px-sm"
                v-model="postForm.group"
                :options="_optionListGroups"
                @filter="filterFnGroups"
                multiple
                emit-value
                use-chips
                map-options
                dense
                :rules="[val=> val.length !== 0 || 'Campo obrigatório']"
                use-input
                input-debounce="0"
                label="Selecione os grupos"
                color="default-pink"
                ><template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      Não encontrado
                    </q-item-section>
                  </q-item>
                </template></q-select
              >
              <q-checkbox
                left-label
                v-model="allGroups"
                label="Selecionar todos"
                color="default-pink"
                @update:model-value="selectedFilteredGroups"
              />
            </div>
          </div>

          <div class="row justify-between">
            <q-input
              class="q-py-sm q-px-sm col-6"
              dense
              type="text"
              v-model="postForm.date_start"
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy
                    cover
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-date
                      v-model="postForm.date_start"
                      mask="DD/MM/YYYY HH:mm:ss"
                    >
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                        />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
                <q-icon name="access_time" class="cursor-pointer">
                  <q-popup-proxy
                    cover
                    transition-show="scale"
                    transition-hide="scale"
                  >
                    <q-time
                      v-model="postForm.date_start"
                      mask="DD/MM/YYYY HH:mm:ss"
                      format24h
                    >
                      <div class="row items-center justify-end">
                        <q-btn
                          v-close-popup
                          label="Close"
                          color="primary"
                          flat
                        />
                      </div>
                    </q-time>
                  </q-popup-proxy>
                </q-icon>
              </template>
            </q-input>
            <!-- :rules="[
                (val) => val.length != '' || 'Campo Obrigatório',
                (val) => val.length <= 500 || 'máximo de 500 caracteres',
              ]" -->
            <q-input
              type="text"
              class="q-py-sm q-px-sm col-5"
              dense
              placeholder="Escreva um link de uma live"
              v-model="postForm.link_live"
              maxlength="500"
            />
          </div>
          <q-input
            type="textarea"
            class="q-py-sm q-px-sm"
            color="black"
            placeholder="Escreva uma descrição"
            v-model="postForm.description"
            lazy-rules
            :rules="[(val) => val.length <= 500 || 'máximo de 500 caracteres']"
          />
          <div class="flex row justify-between">
            <q-input
              type="text"
              class="q-py-sm q-px-sm col-5"
              dense
              placeholder="Pontuação para curtir"
              v-model="postForm.pointPostLike"
            >
              <q-tooltip>
                Valor Padrão {{ pointLikePost == undfined ? 0 : pointLikePost }}
              </q-tooltip>
            </q-input>
            <q-input
              type="text"
              class="q-py-sm q-px-sm col-5"
              dense
              placeholder="Pontuação para comentar"
              v-model="postForm.pointPostComment"
            >
              <q-tooltip>
                Valor Padrão
                {{ pointCommentPost == undfined ? 0 : pointCommentPost }}
              </q-tooltip>
            </q-input>
          </div>

          <div class="flex column q-pl-md main">
            <small>Adicionar</small>
            <div class="flex row justify-between">
              <q-btn
                title="Imagem"
                round
                dense
                size="md"
                color="default-pink"
                @click="openFileSelect('image')"
              >
                <q-img
                  width="60%"
                  src="../../../../assets/icons/image.svg"
                  spinner-color="white"
                />
              </q-btn>
              <q-btn
                title="Vídeos"
                round
                dense
                size="md"
                color="default-pink"
                @click="openFileSelect('video')"
              >
                <q-img
                  width="60%"
                  src="../../../../assets/icons/film.svg"
                  spinner-color="white"
                />
              </q-btn>
              <q-btn
                title="Arquivos"
                round
                dense
                size="md"
                color="default-pink"
                @click="openFileSelect('application')"
              >
                <q-img
                  width="50%"
                  src="../../../../assets/icons/file.svg"
                  spinner-color="white"
                />
              </q-btn>
              <q-btn
                title="Scorm"
                round
                dense
                size="md"
                color="default-pink"
                @click="openFileSelect('x-zip-compressed')"
              >
                <Interactivity color="white" />
              </q-btn>
            </div>
          </div>
          <div
            class="flex row q-pl-sm"
            v-if="postForm.file.length > 0 || postForm.oldFiles.length > 0"
          >
            <CaroselFiles
              :files="postForm.file"
              :oldFiles="postForm.oldFiles"
              @removeItem="(index) => removeItem(index)"
              @removeOldItem="(file) => removedOldFiles(file)"
            />
          </div>
        </q-form>
        <q-card-actions align="right">
          <div class="flex row justify-end q-pr-md">
            <q-btn
              color="default-pink"
              text-color="black"
              no-caps
              unelevated
              flat
              label="Cancelar"
              @click="$emit('cancel')"
            />
            <q-btn
              v-if="post == null"
              color="default-pink"
              text-color="white"
              no-caps
              unelevated
              label="publicar"
              @click="submit()"
            />
            <q-btn
              v-else
              color="default-pink"
              text-color="white"
              no-caps
              unelevated
              label="Salvar"
              @click="save()"
            />
          </div>
        </q-card-actions>
        <DialogMedia
          :showDialog="isShowMediaForm"
          :fileType="fileType"
          optionalImage="image"
          @selectMedia="(file) => checkFileType(file)"
          @closeDialog="() => (isShowMediaForm = false)"
          @gameImage="(value) => checkImage(value)"
          @gameName="
            (value) => {
              setNameGame(value);
            }
          "
        >
        </DialogMedia>
        <DialogCategory
          v-if="addCategory"
          :showDialog="addCategory"
          :categorySelected="categorySelected"
          @closeDialog="
            () => {
              addCategory = false;
            }
          "
          @saveNewCategory="(category) => addNewCategory(category)"
        >
          <template v-slot:categoryList>
            <div class="row q-px-md">
              <span class="title-input-form">Categoria adicionadas</span>
            </div>
            <div class="form-input col-12 column">
              <Table
                class="full-height"
                :list="listCategories"
                :columns="columnsTable"
                :addEdit="true"
                :addRemove="true"
                @edit="(category) => editCategory(category)"
                @disabled="(student) => disabledCategory(student)"
              >
                <template v-slot:body="props">
                  <div>
                    <q-btn
                      dense
                      round
                      color="default-pink"
                      title="Editar"
                      flat
                      @click="() => editCategory(props.props.row)"
                      icon="o_edit_note"
                    />
                    <q-btn
                      dense
                      round
                      color="default-pink"
                      title="Remover"
                      flat
                      @click="() => disabledCategory(props.props.row)"
                      icon="o_delete_outline"
                    />
                  </div>
                </template>
              </Table>
            </div>
          </template>
        </DialogCategory>
      </section>
    </q-card>
  </q-dialog>
</template>

<script>
/* Icons */
import Interactivity from "@/assets/icons/custom/Interactivity.vue";

//----SERVICES
import GroupServices from "@/services/GroupServices";
import PointServices from "@/services/PointServices";
import PostDataServices from "@/services/PostDataServices";

//----COMPONENTS
import DialogMedia from "@/components/Feed/post/dialogs/DialogMedia.vue";
import DialogCategory from "@/components/Feed/post/dialogs/DialogCategory.vue";
import CaroselFiles from "@/components/Feed/post/carosel/CaroselFiles.vue";
import CarouselPosts from "@/components/Feed/post/carosel/CarouselPosts.vue";
import OutlineBtn from "@/components/shared/buttons/OutlineBtn.vue";
import Table from "@/components/shared/tables/TableDynamic.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";

//----UTILS
import CanUserAccessClass from "@/utils/CanUserAccessClass";
import { ENV } from "@/utils/env";

//----VUE
import { ref, reactive, onMounted, watch } from "vue";
import { useStore } from "vuex";

//----QUASAR
import { useQuasar, date } from "quasar";

export default {
  name: "CreatePostCard",
  components: {
    DialogMedia,
    CaroselFiles,
    CarouselPosts,
    OutlineBtn,
    Table,
    DefaultBtn,
    DialogCategory,
    Interactivity,
  },
  props: {
    post: Object,
    isLoading: Boolean,
    showDialog: Boolean,
  },
  emits: ["cancel", "publish", "save"],
  setup(props, { emit }) {
    //----CONSTANTS
    const _groupServices = new GroupServices();
    const _pointServices = new PointServices();
    const _postDataServices = new PostDataServices();

    const urlAwsBucket = ENV.URL_AWS_BUCKET;
    const fileTypeScorm = ref(["x-zip-compressed", "zip"]);
    const $q = useQuasar();
    const store = useStore();
    const filterListItens = ref([
      { name: "Tudo", value: "1", active: true},
      { name: "Favoritos", value: "5" ,active: true},
      { name: "Conquistas", value: "2",active: store.state.userdata.company_id !== 7 },
      { name: "Conhecimento", value: "3" ,active: true},
      { name: "Dúvidas", value: "4" ,active: true},
      { name: "QualiOn", value: "6" ,active: store.state.userdata.company_id === 7},
    ]);
    const columnsTable = ref([
      {
        name: "label",
        required: true,
        label: "Nome",
        align: "left",
        field: (row) => row.label,
        sortable: true,
      },
      {
        name: "acao",
        align: "center",
        label: "Ações",
        field: "acoes",
        sortable: false,
      },
    ]);

    //----VARIABLES
    const _optionListGroups = ref([]);
    let formRef = ref(null);
    let listGroups = ref([]);
    let listCategories = ref([]);
    let canUserAccessClass = new CanUserAccessClass();
    let listBranches = ref([]);
    let optionListBranches = ref([]);
    let allGroups = ref(false);

    let categorySelected = ref({
      id: "",
      image: "",
      label: "",
    });
    let isShowMediaForm = ref(false);
    let isShowRestrictOptions = ref(false);
    let checkAllGroup = ref(false);
    let file = ref([]);
    let fileType = ref("");
    let pointCommentPost = ref("");
    let pointLikePost = ref("");
    let fileSelectName = ref("");
    let restrictPostList = ref([
      { name: "Tel Comercio", value: false },
      { name: "Tel Barrafunda", value: false },
      { name: "Drumond Matriz", value: false },
      { name: "Cátedra Itabuna", value: false },
      { name: "Tel Barueri", value: false },
    ]);
    let addCategory = ref(false);
    let newCategoryName = ref("");
    let canAddCategory = ref(false);
    let postForm = reactive({
      id: props.post == null ? "" : props.post.postId,
      tagSelected: "",
      tagnNameSelected: "",
      link_live: props.post == null ? "" : props.post.link_live,
      titlePost: props.post == null ? "" : props.post.title,
      type_image: props.post == null ? null : props.post.type_image,
      description: props.post == null ? "" : props.post.description,
      editOtherTutor:
        props.post == null ? true : props.post.edition_other_tutor,
      pointPostLike: null,
      category_id: null,
      pointPostComment: null,
      date_start:
        props.post == null
          ? date.formatDate(new Date(), "DD/MM/YYYY H:mm:ss")
          : date.formatDate(props.post.datetime_Start, "DD/MM/YYYY H:mm:ss"),
      group: [],
      restinctPost: [],
      file: [],
      oldFiles: [],
      removedFiles: [],
      gameImage: null,
      Name_game: "",
      branches: [],
    });

    let isShowDialog = ref(props.showDialog);

    watch(
      () => props.showDialog,
      (newState) => {
        isShowDialog.value = newState;
      }
    );

    /* Lifecycles */

    onMounted(() => {
      _listAllGroups();
      _getEventPoint();
      _listAllCategories();
      _getSelectedSelections();
      _loadOldFiles();
      validateEdition("posts-category-add");
      getBranches();
    });

    watch(
      () => postForm.branches,
      (newBranch, oldBranch) => {
        if (newBranch.length == 0) {
          postForm.group = null;
          postForm.group = [];
          allGroups.value = false;
        }

        if (newBranch.length != oldBranch.length) {
          _optionListGroups.value = listGroups.value;
        }

        if (newBranch.length != 0) {
          _optionListGroups.value = listGroups.value.filter((group) => {
            return newBranch.includes(group.branch_id);
          });

          let groupValues = _optionListGroups.value.map((group) => {
            return group.value;
          });

          postForm.group = groupValues.filter((value) => {
            if (postForm.group.includes(value)) {
              return value;
            }
          });
        }
      }
    );

    watch(
      () => postForm.group,
      (newGroup, oldGroup) => {
        if (newGroup.length == 0) {
          allGroups.value = false;
        }
      }
    );

    watch(_optionListGroups, (newGroup, oldGroup) => {
      if (allGroups.value && postForm.branches.length != 0) {
        if (newGroup.length > oldGroup.length) {
          _optionListGroups.value.filter((group) => {
            if (!postForm.group.includes(group.value)) {
              postForm.group.push(group.value);
            }
          });
        }
      }
    });

    async function getBranches() {
      await _groupServices
        .listBranches()
        .then((response) => {
          response.data.map((item) => {
            if (item.status) {
              listBranches.value.push({
                label: item.name_branch,
                value: item.id,
              });
            }
          });
          optionListBranches.value = listBranches.value;
          if (props.post != null) {
            props.post.branch_id.forEach((branch) => {
              postForm.branches.push(branch);
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    function filterFnBranches(val, update) {
      if (val === "") {
        update(() => {
          listBranches.value = optionListBranches.value;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        listBranches.value = optionListBranches.value.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    }

    async function validateEdition(RouteName) {
      if (canUserAccessClass.canUserAccess(RouteName, false)) {
        canAddCategory.value = true;
        return;
      }
    }

    function _getSelectedSelections() {
      if (props.post != null) {
        filterListItens.value.map((tags) => {
          if (props.post.post_Classification_Id == tags.value) {
            postForm.tagSelected = tags.value;
            postForm.tagnNameSelected = tags.name;
          }
        });
      }
    }

    async function _getEventPoint() {
      let EVENT_COMMNET_POST = 1;
      let response = await _pointServices.getEvent(EVENT_COMMNET_POST);
      pointCommentPost.value = response.data.value;

      let EVENT_LIKE_POST = 2;
      let responseLike = await _pointServices.getEvent(EVENT_LIKE_POST);
      pointLikePost.value = responseLike.data.value;
    }

    async function _listAllCategories() {
      await _postDataServices
        .getAllCategories(store.state.userdata.company_id)
        .then((response) => {
          listCategories.value = [];
          response.data.map((item) => {
            listCategories.value.push({
              label: item.description,
              value: item.id,
              image: item.image_path,
            });
          });
          //ADICIONAR CATEGORIA PARA EDITAR POST
          if (props.post == null) return;
          listCategories.value.map((category) => {
            if (props.post.category_description == category.label) {
              postForm.category_id = category.value;
            }
          });
        });
    }

    function _loadOldFiles() {
      if (props.post != null) {
        props.post.files.forEach((oldFile) => {
          postForm.oldFiles.push(oldFile);
        });
      }
    }

    async function _listAllGroups() {
      await _groupServices.listGroups().then((response) => {
        response.data.map((item) => {
          listGroups.value.push({
            label: item.group_name,
            value: item.id,
            company: item.company_id,
            branch_id: item.branch_id,
          });
        });
        _optionListGroups.value = listGroups.value;

        //ADICIONAR GRUPOS PARA EDITAR POST
        if (props.post != null) {
          listGroups.value.map((group) => {
            if (
              props.post.groups_id.filter((id) => id == group.value).length > 0
            ) {
              postForm.group.push(group.value);
            }
          });
        }
      });
    }

    function selectedFilteredGroups() {
      if (allGroups.value) {
        _optionListGroups.value.filter((group) => {
          if (!postForm.group.includes(group.value)) {
            postForm.group.push(group.value);
          }
        });
      } else {
        postForm.group = null;
        postForm.group = [];
      }
    }

    function selectTag(value, name) {
      postForm.tagSelected = value;
      postForm.tagnNameSelected = name;
    }

    function saveRestrinctPost() {
      postForm.restinctPost = restrictPostList;
    }

    function openFileSelect(typeFile) {
      postForm.type_image = typeFile;
      fileType.value = typeFile;
      isShowMediaForm.value = true;
    }

    function checkFileType(files) {
      //if(files == < 1) return;
      return files.filter((file) => {
        let type = file.type.split("/");
        if (fileType.value == "x-zip-compressed" || fileType == "zip") {
          for (let index = 0; index < type.length; index++) {
            if (fileTypeScorm.value.includes(type[index])) {
              files[0].fileType = fileType;
              postForm.file.push(files[0]);
              return file;
            }
          }
        } else {
          if (
            type[0] === fileType.value &&
            !fileTypeScorm.value.includes(type[1])
          ) {
            files[0].fileType = fileType;
            postForm.file.push(files[0]);
            return file;
          }
        }
      });
    }

    function checkImage(files) {
      return files.filter((file) => {
        let type = file.type.split("/");
        if (type[0] === "image") {
          postForm.gameImage = files;
          return file;
        }
      });
    }

    function setNameGame(name) {
      postForm.Name_game = name;
    }

    function removeFile() {
      postForm.file = null;
    }

    function onRejected() {
      $q.notify({
        type: "negative",
        message: `O arquivo não pode ser aceito`,
      });
    }

    function filterFnGroups(val, update) {
      if (val === "") {
        update(() => {
          if (postForm.branches.length > 0) {
            _optionListGroups.value = listGroups.value.filter((group) => {
              return postForm.branches.includes(group.branch_id);
            });
          }
          _optionListGroups.value = listGroups.value;
        });
      }

      update(() => {
        const needle = val.toLowerCase();
        _optionListGroups.value = _optionListGroups.value.filter((v) => {
          if (postForm.branches.length > 0) {
            return (
              v.label.toLowerCase().indexOf(needle) > -1 &&
              postForm.branches.includes(v.branch_id)
            );
          }
          return v.label.toLowerCase().indexOf(needle) > -1;
        });
      });
    }

    function checkOrDescheckGroups(state) {
      if (state) {
        listGroups.value.forEach((group) => {
          postForm.group.push(group.value);
        });
      } else {
        postForm.group = null;
        postForm.group = [];
      }
      checkAllGroup.value = state;
    }

    function removeItem(index) {
      postForm.file.splice(index, 1);
    }

    function removedOldFiles(oldFile) {
      postForm.removedFiles.push(oldFile.name);
      postForm.oldFiles = postForm.oldFiles.filter(
        (file) => file.name != oldFile.name
      );
    }

    async function addNewCategory(category) {
      //loading.value = true;
      // event.preventDefault();
      if (category.idCategory != 0) {
        const formData = new FormData();
        formData.append("name", category.newCategoryName);
        formData.append("category_image", category.file);

        await _postDataServices
          .updateCategories(category.idCategory, formData)
          .then(async (response) => {
            await _listAllCategories();
            alertErro("Categoria editada com sucesso", "green-9");
          })
          .catch((error) => {
            alertErro(error.response.data.toString());
          });
      } else {
        const formData = new FormData();
        formData.append("companyId", store.state.userdata.company_id);
        formData.append("name", category.newCategoryName);
        formData.append("category_image", category.file);

        await _postDataServices
          .addCategories(formData)
          .then(async (response) => {
            await _listAllCategories();
            alertErro("Categoria adicionada com sucesso", "green-9");
          })
          .catch((error) => {
            alertErro(error.response.data.toString());
          });
      }
    }

    function editCategory(category) {
      categorySelected.value.id = category.value;
      categorySelected.value.image = urlAwsBucket + category.image;
      categorySelected.value.label = category.label;
    }

    function disabledCategory(category) {
      $q.notify({
        message: `Deseja remover o categoria ${category.label}?`,
        color: "red-9",
        position: "bottom",
        actions: [
          {
            label: "Remover",
            color: "white",
            handler: () => {
              _removeCategory(category);
            },
          },
        ],
      });
    }

    async function _removeCategory(category) {
      await _postDataServices
        .removeCategories(category.value)
        .then(async () => {
          await _listAllCategories();
        })
        .catch((error) => {
          alertErro("Erro ao remover categoria");
        });
    }

    function alertErro(message, color = "red-9") {
      $q.notify({
        message: message,
        color: color,
        position: "top",
      });
    }

    async function submit() {
      if (await formRef.value.validate()) {
        if (postForm.tagnNameSelected == "") {
          $q.notify({
            message: "Por favor, informe a tag.",
            color: "red-9",
            position: "top",
          });
          return;
        }

        if (postForm.group.length === 0){
          $q.notify({
            message: "Por favor, informe os grupos.",
            color: "red-9",
            position: "top",
          });
          return
        }

        if (postForm.description == "") {
          $q.notify({
            message: "Por favor, informe a descrição.",
            color: "red-9",
            position: "top",
          });
          return;
        }
        emit("publish", postForm);
      }
    }

    async function save() {
      if (await formRef.value.validate()) {
        if (postForm.tagnNameSelected == "") {
          $q.notify({
            message: "Por favor, informe a tag.",
            color: "red-9",
            position: "top",
          });
          return;
        }

        if (postForm.group.length === 0){
          $q.notify({
            message: "Por favor, informe os grupos.",
            color: "red-9",
            position: "top",
          });
          return
        }

        if (postForm.description == "") {
          $q.notify({
            message: "Por favor, informe a descrição.",
            color: "red-9",
            position: "top",
          });
          return;
        }
        emit("save", postForm);
      }
    }

    return {
      //----VARIABLES
      postForm,
      filterListItens,
      isShowRestrictOptions,
      addCategory,
      pointLikePost,
      pointCommentPost,
      file,
      fileType,
      isShowMediaForm,
      fileSelectName,
      restrictPostList,
      listGroups,
      listCategories,
      columnsTable,
      checkAllGroup,
      newCategoryName,
      canAddCategory,
      categorySelected,
      listBranches,
      allGroups,
      _optionListGroups,
      isShowDialog,
      formRef,
      //----FUNCTIONS
      selectTag,
      filterFnGroups,
      saveRestrinctPost,
      openFileSelect,
      checkFileType,
      onRejected,
      checkOrDescheckGroups,
      removeFile,
      removeItem,
      removedOldFiles,
      addNewCategory,
      disabledCategory,
      editCategory,
      checkImage,
      setNameGame,
      filterFnBranches,
      selectedFilteredGroups,
      submit,
      save,
    };
  },
};
</script>

<style lang="scss" scoped>
.notification-card {
  width: 80vw;
  max-width: 70vw;
  height: 100%;
  overflow-y: hidden;

  margin-bottom: 1rem;
  padding: 1% 0 0.5% 0;

  background: #fdfdfd;
  border-radius: 20px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.039),
    0px 0.5px 1.5px rgba(0, 0, 0, 0.19);

  > section {
    height: 100%;
    overflow-y: auto;
  }
}

.header {
  display: flex;
  flex-direction: row;
  position: relative;

  .title-card {
    padding-left: 12px;
  }

  &::before {
    content: "";
    width: 3px;
    height: 35px;
    background-color: var(--q-secundary);
    position: absolute;
    border-radius: 0px 2px 2px 0px;
  }

  .avatar-notification {
    width: 35px;
    height: 35px;
    margin: 0 3%;
    overflow: hidden;
    border-radius: 35px;
  }

  .action-header {
    flex: 1;
    button {
      margin-right: 2%;
    }
  }

  .dialog-main {
    h6 {
      font-style: normal;
      font-weight: 600;
      font-size: 1.12rem;
      line-height: 22px;
      color: #000000;
    }
  }
}

.main {
  max-width: 35%;
  small {
    font-style: normal;
    font-weight: 500;
    font-size: 0.8rem;
    color: #00000080;
  }
}
</style>
