<template>
  <div class="row filter-mural">
    <template v-for="item in listItens" :key="item.value">
      <q-chip
          v-if="item.active"
          clickable
          @click="
        () => {
          setFilter(item.value);
          $emit('selectItem', item.value);
        }
      "
          :class="[
        selectedFilter == item.value
          ? getActiveColorButton(item.value)
          : getColorButton(item.value),
      ]"
      >
        <label>{{ item.name }}</label>
      </q-chip>
    </template>

    <!-- <div class="q-mr-sm filter-btn" v-for="item in listItens" :key="item.value">
      <q-item 
        dense 
        clickable
        @click="()=>{setFilter(item.value); $emit('filter', item.value)}"
        :active="selectedFilter == item.value" 
        :class="getColorButton(item.value)"
        :active-class="getActiveColorButton(item.value)" 
      >
        <q-item-section> {{item.name}} </q-item-section>
      </q-item>
    </div> -->
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  name: "FilterButtons",
  props: {
    listItens: Array,
  },
  emits: ["selectItem"],
  setup() {
    let selectedFilter = ref("1");

    function setFilter(value) {
      selectedFilter.value = value;
    }

    function getColorButton(name) {
      switch (name) {
        case "1":
          return "item-pink";
        case "2":
          return "item-yellow";
        case "3":
          return "item-purple";
        case "4":
          return "item-blue";
        case "Quiz":
          return "item-green";
        default:
          return "item-green";
      }
    }
    function getActiveColorButton(name) {
      switch (name) {
        case "1":
          return "active-item-pink";
        case "2":
          return "active-item-yellow";
        case "3":
          return "active-item-purple";
        case "4":
          return "active-item-blue";
        case "Quiz":
          return "active-item-green";
        default:
          return "active-item-green";
      }
    }

    return {
      selectedFilter,
      setFilter,
      getColorButton,
      getActiveColorButton,
    };
  },
};
</script>

<style lang="scss" scoped>
.filter-mural {
  width: 57%;
  min-height: 45px;
  /* justify-content: space-around; */
  flex-wrap: initial;

  margin: 0.5% 0;
  overflow: auto;

  label {
    font-size: 12px;
  }
  .q-item {
    border-radius: 20px;
  }

  .item-pink {
    background: #f8ebec;
    color: var(--q-secundary, #fe4e64);
  }
  .item-yellow {
    background: #fff8ee;
    color: #d49a41;
  }
  .item-purple {
    background: #fdf6fe;
    color: #a459b2;
  }
  .item-blue {
    background: #edf8fd;
    color: #448fb7;
  }
  .item-green {
    background: #f1fae5;
    color: #7cae44;
  }

  .active-item-pink {
    background: var(--q-secundary, #fe4e64);
    color: white;
  }
  .active-item-yellow {
    background: #d49a41;
    color: white;
  }
  .active-item-purple {
    background: #a459b2;
    color: white;
  }
  .active-item-blue {
    background: #448fb7;
    color: white;
  }
  .active-item-green {
    background: #7cae44;
    color: white;
  }
}
</style>
