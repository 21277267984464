<template>
  <div class="main-content row col-12">
    <div class="feed">
      <Mural
        :notificationQuantity="posts.length"
        :breadcrumbs="breadcrumbs"
        titleButton="Novo Post"
        @addNewPost="addNewpost"
      >
        <q-btn
          size="md"
          class="q-px-sm"
          color="default-pink"
          flat
          title="Mais"
          v-show="btnAddPost"
        >
          <q-icon size="sm" class="material-icons-outlined" name="more_horiz" />

          <q-menu>
            <q-list style="min-width: 100px">
              <q-item
                clickable
                v-close-popup
                @click="
                  () => {
                    (isShowPostForm = true), (postEdit = null);
                  }
                "
              >
                <q-item-section avatar>
                  <q-icon
                    size="sm"
                    color="default-pink"
                    class="material-icons-outlined"
                    name="add"
                  />
                </q-item-section>
                <q-item-section> Novo Post </q-item-section>
              </q-item>
              <q-item clickable v-close-popup to="/lighting-test">
                <q-item-section avatar>
                  <q-icon
                    size="sm"
                    color="default-pink"
                    class="material-icons-outlined"
                    name="bolt"
                  />
                </q-item-section>
                <q-item-section> Teste relâmpago </q-item-section>
              </q-item>
              <q-item clickable v-close-popup to="/new-survey">
                <q-item-section avatar>
                  <Survey color="var(--q-secundary)"/>
                </q-item-section>
                <q-item-section> Enquete </q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
      </Mural>
      <div class="flex row full-width justify-between">
        <FilterButtons :listItens="filterListItens" @selectItem="setFilter" />
        <q-input
          outlined
          rounded
          class="q-pa-sm"
          @keyup="searchPost()"
          dense
          v-model="search"
          placeholder="Pesquisar"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </div>
      <div class="body-content scroll" id="FeedScroll">
        <!-- v-on:scroll.native="handleScroll" -->
        <!-- v-if="isShowPostForm" -->
        <CreatePostCard
          v-if="isShowPostForm"
          :showDialog="isShowPostForm"
          :post="postEdit"
          @cancel="cancelPost()"
          @publish="(requestData) => publishPost(requestData)"
          @save="(requestData) => saveEditedPost(requestData)"
          :isLoading="loadingPost"
        >
          <template v-slot:loading>
            <div class="load-post-icon">
              <LoadingIcon />
              <small>Carregando nova postagem {{ loadingPostValue }}%</small>
            </div>
          </template>
        </CreatePostCard>

        <hr />
        <div class="feed-content post-feed">
          <div
            v-for="(post, index) in posts"
            :key="post.id"
            :class="`index-${index}`"
          >
            <PostCard
              :post="post"
              :image="url_aws_bucket + post.postImage"
              @disabled="(requestData) => disabledPost(requestData)"
              @edit="(requestData) => editPost(requestData)"
              @fixPost="(post, datePostFixed) => fixPost(post, datePostFixed)"
              @favoritePost="(post) => favoritePost(post)"
            />
          </div>
          <div v-if="!loading" class="flex row justify-center">
            <DefaultBtn
              titleButton="carregar mais"
              :showIcon="false"
              @click="handleScroll"
            />
          </div>
        </div>
        <div v-if="loading" class="load-icon">
          <LoadingIcon />
        </div>
      </div>
    </div>
    <div class="flex collumn items-center notification">
      <Notication />
    </div>
  </div>
</template>

<script>
import Mural from "@/components/Mural.vue";
import FilterButtons from "@/components/FilterButtons.vue";
import LogoAnimated from "@/components/LoadingIcon.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import RoundedBtn from "@/components/shared/buttons/RoundedBtn.vue";
import LoadingIcon from "@/components/LoadingIcon.vue";
import Notication from "@/components/Feed/notification/Notication.vue";
import CreatePostCard from "@/components/Feed/post/cards/CreatePostCard.vue";
import PostCard from "@/components/Feed/post/cards/PostCard.vue";
import Survey from "@/assets/icons/custom/Survey.vue";

import PostDataServices from "@/services/PostDataServices";
import { ENV } from "@/utils/env";
import CanUserAccessClass from "@/utils/CanUserAccessClass";
import useDebouncedRef from "@/utils/Debounce";

import { useQuasar, date } from "quasar";

import { ref, reactive, onMounted, watch } from "vue";
import {useStore} from "vuex";

export default {
  name: "Home",
  components: {
    Mural,
    FilterButtons,
    CreatePostCard,
    DefaultBtn,
    RoundedBtn,
    Notication,
    PostCard,
    LogoAnimated,
    Survey,
    LoadingIcon,
  },
  setup() {
    let q = new useQuasar();
    let loading = ref(false);
    let loadingPost = ref(false);
    let loadingPostValue = ref(0);
    let url_aws_bucket = ENV.URL_AWS_BUCKET;
    let postDataServices = new PostDataServices();
    let posts = ref([]);
    let postsAll = ref([]);
    let pageNumber = ref(1);
    let pageSize = ref(5);
    let filterSelected = ref(1);
    let canUserAccessClass = new CanUserAccessClass();
    let btnAddPost = ref(true);
    const store = useStore();
    let filterListItens = ref([
      { name: "Tudo", value: "1", active: true},
      { name: "Favoritos", value: "5" ,active: true},
      { name: "Conquistas", value: "2",active: store.state.userdata.company_id !== 7 },
      { name: "Conhecimento", value: "3" ,active: true},
      { name: "Dúvidas", value: "4" ,active: true},
      { name: "QualiOn", value: "6" ,active: store.state.userdata.company_id === 7},
    ]);
    let ListCardsNotifications = ref([
      { type: "Tudo" },
      { type: "Conquistas" },
      { type: "Conhencimento" },
    ]);
    let isShowPostForm = ref(false);
    let breadcrumbs = ref(["Feed"]);
    let postEdit = ref(null);
    const optionsItens = reactive({
      optionsCardsNotifications: ListCardsNotifications.value,
    });
    const search = useDebouncedRef("", 400);

    watch(search, (newQuery) => {
      search.value = newQuery;
      searchPost();
      // init an API request
    });

    async function ValidateActionsPage() {
      if (!canUserAccessClass.canUserAccess("add-post", false)) {
        btnAddPost.value = false;
        return;
      }
    }

    async function getPosts() {
      loading.value = true;
      let response = await postDataServices.getAll(
        pageNumber.value,
        pageSize.value
      );
      if (response.data.data != null) {
        posts.value = response.data.data;
        postsAll.value = response.data.data;
        pageNumber.value = pageNumber.value + 1;
      }

      loading.value = false;
    }


    onMounted(() => {
      ValidateActionsPage();
      getPosts();
    });

    function addNewpost() {
      postEdit.value = null;
    }

    async function setFilter(value) {
      filterSelected.value = value;
      //list of favorites
      if (value == "5") {
        let response = await postDataServices.getAll(1, pageSize.value, true);
        if (response.data.data != null) {
          //pageFavoriteNumber.value = pageFavoriteNumber.value + 1;
          posts.value = response.data.data;
        }
      } else {
        if (value != "1") {
          posts.value = postsAll.value.filter(
            (v) => v.post_Classification_Id == filterSelected.value
          );
        } else {
          posts.value = postsAll.value;
        }
      }
    }

    function showPostForm() {
      isShowPostForm.value = true;
      postEdit.value = null;
    }

    function cancelPost() {
      isShowPostForm.value = false;
      loadingPost.value = false;
    }

    function disabledPost(post) {
      q.notify({
        message: `Deseja remover o post ${post.title}`,
        color: "red-9",
        position: "bottom",
        actions: [
          {
            label: "Remover",
            color: "white",
            handler: async () => {
              _removePost(post.postId);
            },
          },
        ],
      });
    }

    async function editPost(post) {
      let response = await postDataServices.getById(post.postId);
      if (response.data != null) {
        postEdit.value = response.data;
      }

      isShowPostForm.value = true;
    }

    async function fixPost(post, datePostFixed) {
      let format = date.formatDate(datePostFixed, "YYYY-MM-DDTHH:mm:ssZ");
      let isFixed;

      if (post["is_fixed"]) {
        isFixed = false;
      } else {
        isFixed = true;
      }

      let response = await postDataServices.updateFixePost(post["postId"], {
        fixed: isFixed,
        datetime_Expiration_Fixed: format,
      });
      if (response.status == 200) {
        post["is_fixed"] = isFixed;
      }

      //orndernar posts fixados
      posts.value = posts.value.sort((a, b) => {
        if (a["is_fixed"] && !b["is_fixed"]) {
          return -1;
        } else if (!a["is_fixed"] && b["is_fixed"]) {
          return 1;
        } else {
          return 0;
        }
      });
    }

    async function favoritePost(post) {
      let response;
      if (post["is_favorite"]) {
        response = await postDataServices.addPostFavorite(
          post["postId"],
          false
        );
        if (response.status == 200) {
          post["is_favorite"] = false;
        }
      } else {
        response = await postDataServices.addPostFavorite(post["postId"], true);
        if (response.status == 200) {
          post["is_favorite"] = true;
        }
      }
    }

    async function _removePost(postId) {
      await postDataServices.disabled(postId);
      posts.value = posts.value.filter((post) => {
        return post.postId != postId;
      });

      postsAll.value = posts.value;
    }

    async function publishPost(requestData) {
      loadingPost.value = true;

      let date = requestData.date_start.split(" ");
      let dateDays = date[0].split("/").reverse().join("-");

      const formData = new FormData();
      formData.append("title", requestData.titlePost);
      formData.append("description", requestData.description);
      formData.append("category_id", requestData.category_id);
      formData.append("Edition_other_tutor", requestData.editOtherTutor);
      formData.append("link_live", requestData.link_live);
      formData.append("Image_game", requestData.gameImage?.[0]);
      formData.append("Name_game", requestData.Name_game);
      formData.append("datetime_start", `${dateDays} ${date[1]}`);

      formData.append("type_image", requestData.type_image);
      requestData.group.forEach((group) => {
        formData.append("Groups_Id", group);
      });
      requestData.file.forEach((file) => {
        formData.append("files", file);
      });
      formData.append(
        "pointLike",
        requestData.pointPostLike == null ? 0 : requestData.pointPostLike
      );
      formData.append(
        "pointComment",
        requestData.pointPostComment == null ? 0 : requestData.pointPostComment
      );
      formData.append("post_Classification_Id", requestData.tagSelected);
      await postDataServices
        .addPost(formData, _loadingPublish)
        .then((response) => {
          loading.value = false;
          q.notify({
            message: "Post adicionado com sucesso",
            color: "green-9",
            position: "top",
          });
          isShowPostForm.value = false;
          window.location.reload();
        })
        .catch((error) => {
          q.notify({
            message: error.response.data.title.toString(),
            color: "red-9",
            position: "top",
          });
          loadingPost.value = false;
        });
    }

    async function saveEditedPost(requestData) {
      loadingPost.value = true;

      // if (requestData.tagnNameSelected == "") {
      //   q.notify({
      //     message: "Por favor, informe a tag.",
      //     color: "red-9",
      //     position: "top",
      //   });
      //   loading.value = false;
      //   return;
      // }

      // if (requestData.description == "") {
      //   q.notify({
      //     message: "Por favor, informe a descrição.",
      //     color: "red-9",
      //     position: "top",
      //   });
      //   loading.value = false;
      //   return;
      // }

      let date = requestData.date_start.split(" ");
      let dateDays = date[0].split("/").reverse().join("-");

      const formData = new FormData();
      formData.append("Title", requestData.titlePost);
      formData.append("Description", requestData.description);
      formData.append("Category_id", requestData.category_id);
      formData.append("Link_live", requestData.link_live);
      formData.append("Datetime_start", `${dateDays} ${date[1]}`);

      if (requestData.Name_game) {
        formData.append("Name_game", requestData.Name_game);
      }

      if (requestData.gameImage?.[0]) {
        formData.append("Image_game", requestData.gameImage?.[0]);
      }

      formData.append("Edition_other_tutor", requestData.editOtherTutor);
      requestData.group.forEach((group) => {
        formData.append("Groups_Id", group);
      });

      if (requestData.file.length > 0) {
        requestData.file.forEach((file) => {
          formData.append("Files", file);
        });
      }

      if (requestData.removedFiles.length > 0) {
        requestData.removedFiles.forEach((fileOld) => {
          formData.append("Files_delete", fileOld);
        });
      }

      formData.append(
        "PointLike",
        requestData.pointPostLike == null ? 0 : requestData.pointPostLike
      );
      formData.append(
        "PointComment",
        requestData.pointPostComment == null ? 0 : requestData.pointPostComment
      );
      formData.append("Post_Classification_Id", requestData.tagSelected);

      await postDataServices
        .updatePost(requestData.id, formData, _loadingPublish)
        .then((response) => {
          loading.value = false;
          q.notify({
            message: "Post editado com sucesso",
            color: "green-9",
            position: "top",
          });
          loadingPost.value = false;
          window.location.reload();
        })
        .catch((error) => {
          q.notify({
            message: error.response.data,
            color: "red-9",
            position: "top",
          });
          loadingPost.value = false;
        });
    }

    function _loadingPublish(value) {
      loadingPostValue.value = value;
    }

    async function searchPost() {
      if (search.value != "") {
        let response = await postDataServices.getPostsByTitle(
          1,
          pageSize.value,
          search.value
        );
        if (response.data.length > 0) {
          posts.value = response.data;
        }
      } else {
        posts.value = postsAll.value;
      }
    }

    async function handleScroll() {
      // var myElement = document.getElementById("FeedScroll");
      // var topPos = myElement.scrollHeight - myElement.clientHeight - 1;

      // loading.value = true;

      // if (myElement.scrollTop >= topPos ) {
      //   await LoadCommentsByAnother();
      // }

      // loading.value = false;

      loading.value = true;

      await LoadCommentsByAnother();

      loading.value = false;
    }

    async function LoadCommentsByAnother(index, done) {
      if (search.value == "") {
        let response = await postDataServices.getAll(
          pageNumber.value,
          pageSize.value
        );
        if (response.data.data != null) {
          response.data.data.forEach((element) => {
            postsAll.value.push(element);
          });
          await setFilter(filterSelected.value);

          pageNumber.value = pageNumber.value + 1;
        }
      } else {
        let response = await postDataServices.getPostsByTitle(
          pageNumber.value,
          pageSize.value,
          search.value
        );

        if (response.data != null) {
          response.data.forEach((element) => {
            posts.value.push(element);
          });
        }
        pageNumber.value + 1;
      }
    }

    return {
      filterListItens,
      isShowPostForm,
      ListCardsNotifications,
      breadcrumbs,
      postEdit,
      disabledPost,
      search,
      fixPost,
      favoritePost,
      setFilter,
      addNewpost,
      showPostForm,
      cancelPost,
      publishPost,
      saveEditedPost,
      LoadCommentsByAnother,
      posts,
      loading,
      loadingPost,
      loadingPostValue,
      url_aws_bucket,
      btnAddPost,
      handleScroll,
      searchPost,
      editPost,
    };
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-height: 95vh;
  overflow: auto;
  padding: 0.5rem;

  hr {
    width: 100%;
    height: 1px;
    min-height: 1px;
    background: #e5e5e5;
    border: none;
    margin-bottom: 0.5rem;
  }

  @media (min-width: 800px) {
    flex-direction: row;

    .notification .activity-day-card {
      min-width: 70%;
      margin: 2% 0;
    }
  }
}

.feed,
.notification {
  height: 100%;

  padding: 1% 1rem;
  overflow-y: auto;
}

.feed {
  display: flex;
  flex: 2.5;
  flex-direction: column;
  align-items: center;

  position: relative;

  /* border: 2px solid blue; */
  .body-content {
    // height: 70vh;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
  .feed-content {
    width: 100%;
    height: 440px;
    padding: 0.5rem;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #e5e5e5;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: white;
    }
  }
}

.load-post-icon {
  width: 100%;
  height: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  bottom: 0;

  svg {
    height: auto;
    width: 50px;
  }
}

.load-icon {
  height: auto;
  width: 50px;
  margin: 0 50%;
  position: absolute;
  bottom: 0;
}

.notification {
  display: flex;
  flex-direction: column;
  flex: 1;
  /* border: 2px solid red; */
  border: 1.5px solid #e3e8eb;
  border-radius: 40px;
  //margin: 0.5rem;
  background: #fafafa;
}
</style>
